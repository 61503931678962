@import '../../../../scss/theme-bootstrap';

///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///

@mixin gnav-linkstyle {
  @include font--nav;
  display: block;
  height: $header-height--mobile;
  line-height: $header-height--mobile;
  margin: 0 11px;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
  @include breakpoint($landscape-up) {
    height: $header-height;
    line-height: $header-height + 7;
    margin: 0 8px;
  }
}

.gnav-util-formatter__items {
  float: $rdirection;
  padding-#{$rdirection}: 15px;
  @include breakpoint($landscape-up) {
    float: none;
    padding-#{$rdirection}: 0;
  }
}

.gnav-util-formatter__item {
  float: $ldirection;
  .menu__item {
    display: block;
  }
  .menu__link {
    @include gnav-linkstyle;
    @include link-stub();
    &:before {
      @include transition(opacity 0.3s ease);
      opacity: 0;
      bottom: 15px;
    }
    &.active,
    &:hover {
      &:before {
        bottom: 15px;
        opacity: 1;
      }
    }
    @include breakpoint($landscape-up) {
      color: $color-black;
      .gnav-active &,
      .offer-active & {
        color: $color-white;
      }
    }
  }
}

.gnav-util {
  &--account {
    .gnav-util__account-title--signed-in {
      display: none;
    }
    .elc-user-state-logged-in & {
      @include breakpoint($landscape-up) {
        position: relative;
      }
      .gnav-util__account-title--signed-out {
        display: none;
      }
      .gnav-util__account-title--signed-in {
        display: block;
      }
      .gnav-util__account-loyalty-point {
        position: relative;
        top: -55px;
        text-align: center;
        display: block;
      }
    }
    // My Loyalty Enrollment Post Signin
    &.post-signin-smashcash {
      @include breakpoint($landscape-up) {
        position: static;
      }
    }
  }
  &--loyalty-join {
    // The rules here are similar to the above, but best kept separate.
    .gnav-util__account-title--signed-in {
      display: none;
    }
    .elc-user-state-logged-in & {
      .gnav-util__account-title--signed-out {
        display: none;
      }
      .gnav-util__account-title--signed-in {
        display: block;
      }
    }
  }
  &--cart {
    @include breakpoint($landscape-up) {
      position: relative;
    }
    .viewcart &,
    .checkout & {
      display: none;
      @include breakpoint($portrait-up) {
        display: inline-block;
      }
    }
  }
  &--search {
    .viewcart &,
    .checkout & {
      display: none;
      @include breakpoint($portrait-up) {
        display: inline-block;
      }
    }
  }
}

.gnav-util__link {
  @include gnav-linkstyle;
  .mobile-gnav-active &,
  .gnav-active &,
  .offer-active & {
    color: $color-white;
  }
  @include breakpoint($landscape-up) {
    color: $color-black;
    @include link-stub();
    &:before {
      @include transition(opacity 0.3s ease);
      opacity: 0;
      bottom: 15px;
    }
    &.active,
    &:hover {
      &:before {
        bottom: 15px;
        opacity: 1;
      }
    }
  }

  // account link
  &--account {
    position: relative;
    padding-#{$rdirection}: 10px;
    margin-#{$rdirection}: 0;
    line-height: 66px;
    // override link-stub() size to account for arrow;
    &:before {
      .gnav-util--account & {
        display: none;
      }
    }
    .svg-icon {
      width: 20px;
      height: 20px;
      .gnav-active & {
        fill: $color-white;
      }
    }
  }
}

.gnav-util__icon {
  display: block;
  line-height: $header-height--mobile;
  padding: 0 13px;
  position: relative;
  .mobile-gnav-active &,
  .gnav-active &,
  .offer-active & {
    color: $color-white;
  }
  @include breakpoint($landscape-up) {
    line-height: $header-height;
  }
  &--cart {
    padding-#{$ldirection}: 11px;
    padding-#{$rdirection}: 0;
    // custom breakpoint
    @include breakpoint(375px) {
      padding-#{$ldirection}: 22px;
    }
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 15px;
    }
    .gnav-util__cart-count {
      font-family: $font--sans;
      letter-spacing: 0;
      position: absolute;
      top: 4px;
      #{$rdirection}: 23px;
      font-size: 14px;
    }
  }
  .svg-icon {
    width: 20px;
    height: 20px;
    .mobile-gnav-active &,
    .gnav-active &,
    .offer-active & {
      fill: $color-white;
    }
  }
}

.gnav-util--search {
  .gnav-util__content {
    &.hidden {
      visibility: visible;
    }
  }
}

.gnav-util__content {
  @include transition(opacity 0.4s ease-out);
  color: $color-black;
  position: absolute;
  #{$ldirection}: 0;
  background: $color-white;
  width: 100%;
  z-index: 10;
  #{$ldirection}: 0;
  opacity: 1;
  @include breakpoint($landscape-up) {
    border-top: 1px solid $color-white;
  }
  &.hidden {
    display: block !important;
    #{$ldirection}: -100%;
    opacity: 0;
  }
  &-close {
    position: absolute;
    z-index: 2;
    #{$rdirection}: 15px;
    top: 13px;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      top: 12px;
      #{$rdirection}: 20px;
    }
    .icon {
      font-size: 24px;
    }
  }
  &-inner {
    padding: 20px 15px;
    @include breakpoint($landscape-up) {
      top: $header-height;
      padding: 30px 20px;
    }
    .gnav-util__content--search & {
      padding: 45px 0 0 0;
      min-height: 135px;
      @include breakpoint($landscape-up) {
        padding: 62px 0 45px 0;
      }
    }
  }
}

// Account Overlay
.gnav-util__content--account {
  .elc-user-state-logged-in & {
    @include breakpoint($landscape-up) {
      width: 297px;
      #{$ldirection}: auto;
      #{$rdirection}: 0;
    }
  }
  // My Loyalty Enrollment Post Signin
  .post-signin-smashcash & {
    width: 100%;
    #{$ldirection}: 0;
    #{$rdirection}: auto;
  }
}

// CART Overlay
.gnav-util__content--cart {
  #{$ldirection}: auto;
  #{$rdirection}: 0;
  height: 450px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $color-bg;
  text-transform: uppercase;
  max-width: 400px;
  @include breakpoint($portrait-up) {
    max-width: none;
    width: 400px;
  }
  @include breakpoint($landscape-up) {
    width: 440px;
    overflow: hidden;
  }
  .gnav-util__content-edit {
    float: $ldirection;
    position: relative;
    top: 20px;
    #{$ldirection}: 20px;
    border-bottom: solid 1px $color-transparent--dark-black;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
